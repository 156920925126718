<template>
  <b-card title="Post Category List">
    <b-row class="mb-2 justify-content-between">
      <b-col cols="12" lg="4" class="d-flex align-items-center">
        <b-button
          @click="cleanUpForm()"
          v-b-modal.form-post-modal
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create Post Category
        </b-button>
      </b-col>
    </b-row>
    <b-row class="justify-content-start">
      <b-col cols="12" lg="3">
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="category">Category:</label>
          <v-select
            v-model="filter.group"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="--Type--"
            :options="groupList"
            :reduce="(group) => group.value"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :postCategory="postCategory"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :create-item="createItem"
      :current-page="currentPage"
      :editId="editId"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import Table from "@/components/post-category/Table.vue";
import vSelect from "vue-select";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      postCategory: {},
      link: "",
      isLoadingExport: false,
      formPayload: {
        name: "",
      },
      editId: null,
      filter: {},
      validations: "",
      groupList: [
        {
          value: "article-daily-life",
          name: "Article Daily Life",
        },
        {
          value: "article-whats-new",
          name: "Article What's New",
        },
        {
          value: "smart-module",
          name: "Smart Module",
        },
        {
          value: "inspiring-sister",
          name: "Inspiring Sister",
        },
      ],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        name: "",
      };
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        name: item.name,
      };
      this.$bvModal.show("form-post-modal");
    },
    createItem() {
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/post-categories/create", this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-post-modal");
          this.getData(this.currentPage);
          successNotification(
            this,
            "Success",
            "Post Category successfully created"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateItem() {
      this.isLoading = true;
      this.$http
        .patch(
          `/api/v1/admin/post-categories/update/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.$bvModal.hide("form-post-modal");
          this.getData();
          successNotification(
            this,
            "Success",
            "Post Category successfully updated!"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/post-categories/list", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.postCategory = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Post Category?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/post-categories/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Post Category successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
